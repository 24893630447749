import { isEmpty } from 'lodash';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import {
  ANALYTICS_SECTION_SUBSCRIPTIONS_TABLE,
  DEFAULT_FAVORITE_ARTISTS_COUNT,
  FREE_FAVORITE_ARTISTS_COUNT,
  IMAGE_SOURCE,
  MODULE_EXPORT_PDF,
  MODULE_FUTURESALE,
  MODULE_IMAGE,
  MODULE_INDEXES,
  MODULE_INDICATOR,
  MODULE_PASTSALE,
  MODULE_STORE,
  THEME_PREMIUM,
  THEME_PROFESSIONAL,
  THEME_STORE,
} from '../../../../constants/rails';
import { areThemesLoading } from '../../../../redux/selectors/subscriptions/physicproducts';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import { getUserContext } from '../../../../redux/selectors/userContext';
import { useViewport } from '../../../../hooks/useViewport';
import Spinner from '../../../common/spinners/Spinner';
import FlipSwitch from '../../../common/ui/FlipSwitch';
import useAnalytics from '../common/useAnalytics';
import A18n from '../../../common/A18n';
import Period from '../common/Period';
import Users from '../common/Users';
import Module from './Module';
import Button from './Button';
import './stylesheet.scss';

const Table = ({ themes, iso3 }) => {
  const { toggleImage: toggleImageAnalytics } = useAnalytics({ section: ANALYTICS_SECTION_SUBSCRIPTIONS_TABLE });
  const { view, requiredIdmodule, filter } = useSelector(getSettings);
  const { idcustomer } = useSelector(getUserContext);
  const [withImages, setWithImages] = useState(true);
  const loading = useSelector(areThemesLoading);
  const { viewportWidth } = useViewport();

  if (viewportWidth < 992) return null;

  const getPrice = ({ theme }) => {
    const product = view === 'upgrade' ? theme.upgrade : theme.product;

    if (product) {
      if (withImages) return product.price[iso3];

      return product.price_without_image[iso3];
    }

    return null;
  };

  if (loading)
    return (
      <div className="subscriptions-list-table skeleton">
        <Spinner className="spin" />
      </div>
    );

  if (isEmpty(themes)) return null;

  return (
    <div className="subscriptions-list-table">
      <div className="list-header">
        <h2>
          <A18n rsx="subscriptions.list.m4" />
        </h2>
      </div>
      <table className={classNames(filter)}>
        <thead>
          <tr>
            <th>
              <Users section={ANALYTICS_SECTION_SUBSCRIPTIONS_TABLE} />
              <div style={{ display: 'flex' }}>
                <Period section={ANALYTICS_SECTION_SUBSCRIPTIONS_TABLE} />
              </div>
            </th>
            {!idcustomer && !requiredIdmodule && (
              <th className="essential custom-round">
                <div className={classNames({ head: true, store: filter === 'store' })}>
                  <p className="title">
                    <A18n rsx="subscriptions.list.table.m3" />
                  </p>
                  <p className="price">
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <FormattedNumber style="currency" value={0} currency={iso3.toUpperCase()} />
                  </p>
                  <button
                    type="button"
                    className="subscribe artp-btn"
                    disabled={idcustomer !== undefined}
                    onClick={() => {
                      window.location = '/account/registration';
                    }}
                  >
                    <A18n rsx="subscriptions.list.table.m4" />
                  </button>
                </div>
              </th>
            )}
            {themes.map(theme => {
              const price = getPrice({ theme });
              const disabled = !price || theme.product.forcedDisable || (requiredIdmodule && !theme.product.module.includes(requiredIdmodule));
              return (
                <th className={theme.theme_ref} key={`nm-${theme.theme_ref}`}>
                  {(theme.idtheme === THEME_PREMIUM || (theme.idtheme === THEME_PROFESSIONAL && filter === 'store')) && (
                    <div className="populaire">
                      <A18n rsx="subscriptions.list.cards.m10" />
                    </div>
                  )}
                  {!disabled && [THEME_PREMIUM, THEME_PROFESSIONAL].includes(theme.idtheme) && (
                    <div className="ai">
                      <img src={`${IMAGE_SOURCE}subscription/ai.svg`} alt="Intuitive Art Market" />
                    </div>
                  )}

                  <div className={classNames({ head: true, store: filter === 'store' })}>
                    <p className="title">{theme.theme}</p>
                    <p className="price">
                      {/* eslint-disable-next-line react/style-prop-object */}
                      {!disabled && <FormattedNumber style="currency" value={price} currency={iso3.toUpperCase()} />}
                      {disabled && <A18n rsx="subscriptions.list.table.m5" />}
                    </p>

                    <Button theme={theme} withImages={withImages} disabled={disabled} />
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <Module
            themes={themes}
            label={<A18n rsx="subscriptions.list.table.m11" />}
            checked
            value={theme => {
              if (theme?.module?.includes(MODULE_EXPORT_PDF)) return <A18n rsx="subscriptions.list.table.m26" />;
              if (theme?.module?.includes(MODULE_PASTSALE) || theme?.module?.includes(MODULE_STORE)) return DEFAULT_FAVORITE_ARTISTS_COUNT;
              return FREE_FAVORITE_ARTISTS_COUNT;
            }}
          />
          <Module
            themes={themes}
            label={<A18n rsx="subscriptions.list.table.m12" />}
            checked
            value={theme => {
              if (theme?.module?.includes(MODULE_INDEXES)) return 500;
              if (theme?.module?.includes(MODULE_PASTSALE)) return 50;
              return 10;
            }}
          />
          <Module
            themes={themes}
            label={<A18n rsx="subscriptions.list.table.m13" />}
            checked
            value={theme => {
              if (theme?.module?.includes(MODULE_INDEXES)) return 500;
              if (theme?.module?.includes(MODULE_PASTSALE)) return 50;
              return 10;
            }}
          />
          <Module themes={themes} label={<A18n rsx="subscriptions.list.table.m14" />} checked />
          <Module themes={themes} label={<A18n rsx="subscriptions.list.table.m27" />} checked />
          <Module themes={themes} label={<A18n rsx="subscriptions.list.table.m23" />} checked />
          <Module themes={themes} label={<A18n rsx="subscriptions.list.table.m16" />} checked />
          <Module themes={themes} label={<A18n rsx="subscriptions.list.table.m18" />} checked value={theme => (theme?.module?.includes(MODULE_STORE) ? <A18n rsx="subscriptions.list.table.m26" /> : '3')} />
          {filter !== 'store' && (
            <Module themes={themes} label={<A18n rsx="subscriptions.list.table.m10" />} checked value={theme => (theme ? <A18n rsx="subscriptions.list.table.m28" /> : <A18n rsx="subscriptions.list.table.m9" />)} />
          )}
          {filter === 'store' && (
            <>
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m19" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m43" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m44" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m45" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m46" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m20" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m47" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m48" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m49" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m50" />} />
              <Module
                themes={themes}
                label={<A18n rsx="subscriptions.list.table.m10" />}
                checked
                value={theme => (theme && theme.idtheme !== THEME_STORE ? <A18n rsx="subscriptions.list.table.m28" /> : <A18n rsx="subscriptions.list.table.m9" />)}
              />
            </>
          )}
          <Module themes={themes} idmodule={MODULE_PASTSALE} />
          <Module themes={themes} idmodule={MODULE_PASTSALE} label={<A18n rsx="subscriptions.list.table.m29" />} />
          <Module themes={themes} idmodule={MODULE_FUTURESALE} />
          <Module themes={themes} idmodule={MODULE_IMAGE} forceUncheck={!withImages}>
            <FlipSwitch
              onClick={e => e.stopPropagation()}
              onChange={v => {
                toggleImageAnalytics({ checked: v, slug: 'all_products' });
                setWithImages(v);
              }}
              checked={withImages}
            />
          </Module>
          <Module themes={themes} idmodule={MODULE_INDEXES} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m30" />} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m31" />} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m32" />} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m33" />} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m34" />} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m35" />} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m36" />} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m37" />} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m38" />} />
          <Module themes={themes} idmodule={MODULE_INDICATOR} />
          <Module themes={themes} idmodule={MODULE_EXPORT_PDF} value="500" />
          <Module themes={themes} idmodule={MODULE_EXPORT_PDF} label={<A18n rsx="subscriptions.list.table.m40" />} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m41" />} />
          <Module themes={themes} idmodule={MODULE_INDEXES} label={<A18n rsx="subscriptions.list.table.m42" />} />
          {filter !== 'store' && (
            <>
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m19" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m43" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m44" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m45" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m46" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m20" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m47" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m48" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m49" />} />
              <Module themes={themes} idmodule={MODULE_STORE} label={<A18n rsx="subscriptions.list.table.m50" />} />
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
