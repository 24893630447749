import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { formatFns } from '../../../../utils/dates/format';
import { getHasImageAccess, getLastSubscription, isLogged } from '../../../../redux/slices/userContext/selectors';

const LotTile = ({ lot, url, img, future, location, auctioneer }) => {
  const lastSubscription = useSelector(getLastSubscription);
  const logged = useSelector(isLogged);
  const hasImageAccess = useSelector(getHasImageAccess);

  let dateRsx;
  if (future) {
    dateRsx = 'lots.similar.lot.m3';
  } else if (lastSubscription && logged) {
    dateRsx = lot.price_eur === 0 ? 'lots.similar.lot.m1' : 'lots.similar.lot.m2';
  } else {
    dateRsx = lot.price_eur === 0 ? 'lots.similar.lot.m5' : 'lots.similar.lot.m4';
  }

  const dateLabel = i18next.t(dateRsx).replace('%dt%', lastSubscription && logged ? formatFns(new Date(lot.sale_dt), 'P') : formatFns(new Date(lot.sale_dt), 'y'));

  return (
    <div className="lot-similar">
      <div className="description">
        <div className="header">{dateLabel}</div>
        <div className="img">
          <a className="link-to" target="_blank" rel="noopener noreferrer" href={url} title="">
            <img alt="" src={img} style={!hasImageAccess && img ? { height: 80, marginTop: 30 } : {}} />
          </a>
        </div>

        <div className="link">
          <a className="link-to" target="_blank" rel="noopener noreferrer" href={url}>
            {lot.title}
          </a>
        </div>
        <div>{lot.technique}</div>
        {lastSubscription && logged && <div>{auctioneer}</div>}
        <div>{location}</div>
      </div>
    </div>
  );
};

export default LotTile;
