import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { getAils, getArtist, getCategoriesFuture, getLotsFuture } from '../../../../../redux/slices/artist';
import { getFavoriteCount, getIdcustomer, getPushEmail, hasModuleAccess, isLogged } from '../../../../../redux/slices/userContext/selectors';
import {
  AILTYPE_LOT,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST,
  ANALYTICS_FROM_ARTIST,
  ANALYTICS_SECTION_ARTIST_FUTURE_LOTS,
  ANALYTICS_SECTION_EMAIL_ALERT_CARD,
  ID_FREE_ARTIST,
  MODULE_FUTURESALE,
} from '../../../../../constants/rails';
import { useViewport } from '../../../../../hooks/useViewport';
import DOMView from '../../../../common/observers/DOMView';
import '../../../../common/stylesheets/buttons.scss';
import { UiContext } from '../../common/UiProvider';
import A18n from '../../../../common/A18n';
import Scroll from '../../common/Scroll';
import Categories from './Categories';
import Flip from '../../common/Flip';
import Fake from './Fake';
import Ail from './Ail';
import Lot from './Lot';

const Future = ({ onOpenDrawer, style = {} }) => {
  const idcustomer = useSelector(getIdcustomer);
  const isPushEmail = useSelector(getPushEmail);
  const favoriteCount = useSelector(getFavoriteCount);
  const [lotsAil, setLotsAil] = useState([]);
  const [urlSeeAll, setUrlSeeAll] = useState();
  const [anchorSeeAll, setAnchorSeeAll] = useState();
  const ails = useSelector(getAils);
  const lots = useSelector(getLotsFuture);
  const categories = useSelector(getCategoriesFuture);
  const artist = useSelector(getArtist);
  const logged = useSelector(isLogged);
  const hasFutureSaleAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_FUTURESALE }));
  const [lotCount, setLotCount] = useState(0);
  const [displayLock, setDisplayLock] = useState(false);
  const { lotsFutureRef } = useContext(UiContext);
  const { viewportWidth } = useViewport();

  useEffect(() => {
    if (categories) {
      setLotCount(categories.reduce((nb, c) => nb + c.count, 0));
    }
  }, [categories]);

  useEffect(() => {
    if (artist.id === ID_FREE_ARTIST) {
      setDisplayLock(false);
    } else {
      setDisplayLock(!hasFutureSaleAccess || !logged);
    }
  }, [artist?.id, logged, hasFutureSaleAccess]);

  useEffect(() => {
    if (!isEmpty(get(ails, AILTYPE_LOT)) && get(ails, AILTYPE_LOT)) {
      setLotsAil(get(ails, AILTYPE_LOT));
    }
  }, [ails]);

  useEffect(() => {
    if (lotCount === 0 && lots.notSignedCount > 0) {
      setUrlSeeAll(lots.notSignedUrl);
      setAnchorSeeAll(i18next.t('artists.artist.lots.future.m22'));
    } else {
      if (isEmpty(artist.groupMembers)) {
        setAnchorSeeAll(i18next.t('artists.artist.lots.future.m7'));
      } else if (!isEmpty(artist.groupMembers)) {
        setAnchorSeeAll(i18next.t('artists.artist.lots.future.m14'));
      }
      setUrlSeeAll(lots.url);
    }
  }, [lotCount, lots.notSignedCount]);

  const handleOpenDrawer = ({ section, op, e, lot }) => {
    onOpenDrawer({ section, op, period: 'future', e, lot });
  };

  return (
    <div className="step-future" style={{ position: 'relative', ...style }} ref={lotsFutureRef}>
      <div className="container">
        <h2>
          <A18n rsx="artists.artist.lots.future.m1" />
        </h2>
        <Categories lotCount={lotCount} handleOpenDrawer={handleOpenDrawer} />
      </div>

      {!(isEmpty(lots.items) && isEmpty(lotsAil)) && (
        <Scroll
          aapiBeaconSection={ANALYTICS_SECTION_ARTIST_FUTURE_LOTS}
          containerClassname={classNames({ lots: true, ails: !isEmpty(lotsAil), [`ails-${lotsAil.length}`]: !isEmpty(lotsAil) })}
          renderComplement={() => {
            if (lotCount + lots.notSignedCount === 1) return null;
            return (
              <a
                id="sln_fs"
                href={urlSeeAll}
                className="artp-btn artp-btn-artist"
                onClick={e =>
                  handleOpenDrawer({
                    section: ANALYTICS_SECTION_ARTIST_FUTURE_LOTS,
                    op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST,
                    e,
                  })
                }
              >
                {anchorSeeAll}
              </a>
            );
          }}
        >
          {!isEmpty(lotsAil) && lotsAil.map(ail => <Ail key={ail.id} ail={ail} />)}
          {lots.items
            .filter(lot => !lotsAil.map(ail => ail.discriminant).includes(lot.id))
            .map(lot => {
              if (displayLock && !lot.ail)
                return (
                  <Flip key={`flip-lot-${lot.id}`} style={{ width: '100%', height: 390 }} rotate={viewportWidth >= 768 && (!idcustomer || !isPushEmail || favoriteCount === 0)}>
                    <div>
                      <Lot lot={lot} handleOpenDrawer={handleOpenDrawer} displayLock={displayLock} />
                    </div>
                    <Fake />
                  </Flip>
                );

              return <Lot lot={lot} handleOpenDrawer={handleOpenDrawer} displayLock={displayLock} key={`flip-lot-${lot.id}`} />;
            })}
          <DOMView from={ANALYTICS_FROM_ARTIST} section={ANALYTICS_SECTION_EMAIL_ALERT_CARD} selector=".fake" idartist={artist.id} />
          {(isEmpty(lots.items) || viewportWidth < 768) && <Fake />}
        </Scroll>
      )}
    </div>
  );
};

export default Future;
